
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns49fb114e9d01c8404fdbf8f80b4d9828en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '49fb114e9d01c8404fdbf8f80b4d9828', flattenObject(ns49fb114e9d01c8404fdbf8f80b4d9828en_US), true, true);
        
            import ns49fb114e9d01c8404fdbf8f80b4d9828en from './en.yaml'
            i18n.addResourceBundle('en', '49fb114e9d01c8404fdbf8f80b4d9828', flattenObject(ns49fb114e9d01c8404fdbf8f80b4d9828en), true, true);
        
        export default '49fb114e9d01c8404fdbf8f80b4d9828';
    