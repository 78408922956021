// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'
import { withTranslation } from 'react-i18next'

import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'

import styles from './styles.module.scss'
import MainLogo from 'components/MainLogo/index.js'

const Header = ({ theme, t, }) => {
    return (
        <div
            className={styles.header}
            style={{
                '--background-desktop': `url(${theme.backgroundDesktop})`,
                '--background-tablet': `url(${theme.backgroundTablet})`,
                '--background-mobile': `url(${theme.backgroundMobile})`
            }}
        >
            <span className={styles.logoContainer}>
                <MainLogo />
            </span>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Header))
