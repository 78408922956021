
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns2152b6e4311d6ea8d92260522efe2faeen_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '2152b6e4311d6ea8d92260522efe2fae', flattenObject(ns2152b6e4311d6ea8d92260522efe2faeen_US), true, true);
        
            import ns2152b6e4311d6ea8d92260522efe2faeen from './en.yaml'
            i18n.addResourceBundle('en', '2152b6e4311d6ea8d92260522efe2fae', flattenObject(ns2152b6e4311d6ea8d92260522efe2faeen), true, true);
        
        export default '2152b6e4311d6ea8d92260522efe2fae';
    