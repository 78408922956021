
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns110d26bc2f8fd92064e0e6ba0f955753en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '110d26bc2f8fd92064e0e6ba0f955753', flattenObject(ns110d26bc2f8fd92064e0e6ba0f955753en_US), true, true);
        
            import ns110d26bc2f8fd92064e0e6ba0f955753en from './en.yaml'
            i18n.addResourceBundle('en', '110d26bc2f8fd92064e0e6ba0f955753', flattenObject(ns110d26bc2f8fd92064e0e6ba0f955753en), true, true);
        
        export default '110d26bc2f8fd92064e0e6ba0f955753';
    