// @flow

import * as React from 'react'

import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes'

type Props = {
    y: string,
    x: string,
    width: string,
    primaryColor: string,
    secondaryColor: string,
    eventColor: string,
}

const EventLogo = React.forwardRef(function (props: Props, ref: React.Ref) {
    return (
      <svg viewBox='0 0 2099.32 527.65' x={props.x} y={props.y} width={props.width} ref={ref}>
        <defs>
          <clipPath id='clip-path' transform='translate(-110.07 -37.17)'>
            <rect width='2291.03' height='664.54' />
          </clipPath>
        </defs>
        <title>Upgrade-FallInLove-Logo-FORD</title>
        <g>
          <path fill={props.primaryColor} d='M331.12,405.53H258.7V203.25c0-50.77,33.3-89.07,86.16-89.07h68.26v69.93H356.51c-19.15,0-25.39,7.49-25.39,22.47v27.47h82v67.43h-82Z' transform='translate(-110.07 -37.17)' />
          <path fill={props.primaryColor} d='M593.75,405.53V312.3c0-35.79-16.65-53.27-47-53.27-24.14,0-45.78,18.73-45.78,44.11,0,30.8,22.48,46.62,44.12,46.62a89.82,89.82,0,0,0,32.05-5.83v54.94c-12.49,8.74-26.64,11.66-45.37,11.66-42.45,0-97.39-36.21-97.39-105.72,0-60.77,46.61-106.55,111.12-106.55,62.85,0,114.88,34.13,114.88,98.23v109Z' transform='translate(-110.07 -37.17)' />
          <rect fill={props.primaryColor} x='591.89' y='77.02' width='66.59' height='291.35' />
          <rect fill={props.primaryColor} x='707.6' y='77.02' width='66.6' height='291.35' />
          <path fill={props.primaryColor} d='M1462.62,405.86h-55.36c-70.34,0-103.64-26.64-103.64-94.07V114.51H1376V305.13c0,23.73,7.91,30.8,37,30.8h49.53Z' transform='translate(-110.07 -37.17)' />
          <path fill={props.primaryColor} d='M1572.49,198.58c55.77,0,104.47,47.86,104.47,106.13s-48.7,106.14-104.47,106.14S1468,363,1468,304.71s48.7-106.13,104.47-106.13m0,149c23.31,0,40.37-19.57,40.37-42.88s-17.06-42.86-40.37-42.86-40.37,19.56-40.37,42.86,17.06,42.88,40.37,42.88' transform='translate(-110.07 -37.17)' />
          <path fill={props.primaryColor} d='M1865.51,203.58l-45,152.33c-7.49,25.39-17.06,54.94-50.36,54.94-36.21,0-45.78-25-53.69-54.94l-41.2-152.33h67l27.88,133.18h.84l26.63-133.18Z' transform='translate(-110.07 -37.17)' />
          <path fill={props.primaryColor} d='M1965.39,332.19l-25.39-40,54.52-35.8c-10-6.24-17.89-7.07-25.8-7.07-27.47,0-42.45,27.89-42.45,52,0,25,17.89,51.19,45,51.19,25,0,49.53-22.06,59.52-45.37l47.86,24.56c-19.15,45.78-61.6,79.08-112,79.08-55.77,0-104.47-47.86-104.47-106.14s48.7-106.13,104.47-106.13c46.61,0,77.41,25,99.06,64.51Z' transform='translate(-110.07 -37.17)' />
          <path fill={props.secondaryColor} d='M1093.6,160.12C984.89,40.58,858.26,176.47,936.09,291.36c18,26.6,60.82,68.68,113.13,102a82.87,82.87,0,0,0,88.76,0c52.31-33.3,95.11-75.38,113.13-102,77.83-114.89-48.8-250.78-157.51-131.24' transform='translate(-110.07 -37.17)' />
          <path fill={props.secondaryColor} d='M258.26,96.38a2,2,0,0,0,.05-.26c.11-.55.21-1.1.31-1.64l.06-.41c.08-.45.14-.9.21-1.34,0-.12,0-.25.05-.37.07-.54.14-1.07.2-1.61l0-.33c.05-.44.09-.89.12-1.33l0-.41c0-.53.07-1.06.1-1.58v-.18c0-.47,0-.94,0-1.41,0-.14,0-.28,0-.42V83.53c0-.52,0-1-.06-1.54,0-.13,0-.26,0-.39,0-.43-.05-.86-.08-1.28,0-.09,0-.18,0-.27,0-.5-.09-1-.14-1.49,0-.12,0-.23,0-.35-.05-.4-.1-.8-.16-1.2,0-.12,0-.23-.05-.35-.07-.48-.15-1-.23-1.44a2.56,2.56,0,0,0-.05-.27c-.07-.4-.15-.8-.23-1.19,0-.13-.05-.26-.08-.38-.1-.47-.2-.94-.32-1.4v0h0c-9.29-38.14-59.64-53.2-81.23-1.95C117,37.88,85.91,109.35,132.75,144c10.85,8,33.67,18.69,59,24.56a34.78,34.78,0,0,0,35.13-12.39C243,135.63,254,113,257.43,100c.15-.57.29-1.13.42-1.69,0-.15.07-.3.1-.45.11-.48.21-1,.31-1.44' transform='translate(-110.07 -37.17)' />
          <path fill={props.secondaryColor} d='M565.56,130.85l.13-.17c.28-.35.55-.7.82-1.06l.19-.26.64-.89c0-.08.11-.15.17-.23.24-.36.49-.72.72-1.08l.15-.22L569,126l.17-.28c.22-.36.43-.73.64-1.09a.75.75,0,0,1,.07-.12c.18-.34.37-.67.54-1l.16-.29.53-1,0-.07c.18-.37.35-.74.52-1.11,0-.1.09-.19.13-.29.14-.31.27-.62.41-.94,0-.06.05-.13.08-.2.15-.37.3-.74.44-1.11a2,2,0,0,1,.1-.26c.11-.31.22-.61.32-.91l.09-.27q.2-.56.36-1.11l.06-.21c.1-.31.19-.62.27-.94l.09-.29c.09-.38.19-.75.28-1.12h0c7.24-30.54-23.15-59.55-57.14-30.89-30.86-44.58-79-5-58.2,36.67,4.81,9.65,17.18,25.54,33.11,38.93A27.8,27.8,0,0,0,521.53,162c18.88-8.76,35-20.86,42.14-28.91.32-.34.62-.7.91-1.05l.24-.28.74-.91' transform='translate(-110.07 -37.17)' />
          <path fill={props.secondaryColor} d='M203.63,237.3a.39.39,0,0,1,.08-.1l.49-.65.12-.16.39-.53a1.3,1.3,0,0,1,.1-.15c.15-.21.29-.43.44-.65l.08-.13.35-.55.1-.17c.14-.22.27-.44.39-.67a.16.16,0,0,0,0-.07q.18-.3.33-.6l.1-.18.32-.63a.05.05,0,0,1,0,0l.32-.68.07-.18c.09-.19.17-.37.25-.56s0-.09,0-.13l.27-.67a1,1,0,0,0,.05-.16c.07-.18.14-.37.2-.55l.06-.17c.07-.22.15-.44.22-.67l0-.12c.06-.19.11-.38.17-.57a1.21,1.21,0,0,1,.05-.18c.06-.23.11-.45.17-.68h0c4.39-18.52-14-36.1-34.64-18.73-18.71-27-47.89-3-35.28,22.23,2.92,5.85,10.42,15.48,20.07,23.6a16.86,16.86,0,0,0,17.9,2.38c11.44-5.31,21.2-12.64,25.54-17.52L203,238a2.09,2.09,0,0,1,.14-.17l.45-.55' transform='translate(-110.07 -37.17)' />
          <path fill={props.secondaryColor} d='M2061.2,145.06a2,2,0,0,1,0-.26c-.11-.55-.21-1.1-.31-1.64l-.06-.41c-.08-.45-.15-.9-.21-1.34,0-.12,0-.24-.05-.36-.07-.54-.14-1.08-.2-1.62l0-.33c-.05-.44-.09-.89-.12-1.33l0-.4c0-.54-.08-1.07-.1-1.59v-.18c0-.47,0-.94-.05-1.41v-.42c0-.49,0-1,0-1.46v-.1c0-.51,0-1,.05-1.54,0-.13,0-.26,0-.39l.09-1.28a2.45,2.45,0,0,1,0-.27c0-.5.09-1,.14-1.49,0-.12,0-.23,0-.35.05-.4.1-.8.16-1.2,0-.11,0-.23,0-.35.07-.48.14-1,.23-1.44a2.56,2.56,0,0,1,.05-.27c.07-.4.15-.8.23-1.19,0-.13,0-.25.08-.38.1-.47.2-.94.32-1.4v0h0c9.29-38.14,59.63-53.19,81.23-2,59.72-32.12,90.85,39.34,44,73.94-10.84,8-33.66,18.69-59,24.56a34.78,34.78,0,0,1-35.13-12.39c-16.06-20.49-27.12-43.12-30.53-56.16-.15-.57-.29-1.13-.42-1.69,0-.15-.07-.3-.1-.45-.11-.48-.21-1-.31-1.44' transform='translate(-110.07 -37.17)' />
          <path fill={props.secondaryColor} d='M2115.83,286l-.08-.1c-.17-.21-.33-.43-.49-.64l-.12-.16-.39-.54a.71.71,0,0,0-.1-.14l-.44-.66-.08-.13-.35-.55-.1-.17q-.21-.33-.39-.66l0-.08q-.18-.3-.33-.6l-.1-.18c-.11-.21-.22-.42-.32-.63l0,0-.32-.68-.07-.17c-.09-.19-.17-.38-.25-.57,0,0,0-.08-.05-.13l-.27-.67a1,1,0,0,1-.05-.16c-.07-.18-.14-.37-.2-.55l-.06-.16c-.07-.23-.15-.45-.22-.68l0-.12c-.06-.19-.11-.38-.17-.57a1.21,1.21,0,0,0-.05-.18c-.06-.23-.11-.45-.17-.68h0c-4.39-18.52,14-36.1,34.64-18.73,18.71-27,47.89-3,35.28,22.23-2.92,5.85-10.42,15.48-20.07,23.6a16.86,16.86,0,0,1-17.9,2.38c-11.44-5.31-21.2-12.64-25.54-17.52-.19-.21-.38-.42-.56-.64l-.14-.17-.45-.55' transform='translate(-110.07 -37.17)' />
          <rect fill={'#FFFFFF'} x='916.3' y='161.85' width='28.94' height='116.4' />
          <path fill={'#FFFFFF'} d='M1073.26,315.42V213c0-9.31,7.32-16,17.13-16s15.63,3.83,20,12.31l25.77,51.22h.33V199h28.94V300.46c0,10.64-8,17-15.3,17-7.48,0-13-3.49-20.62-18.29l-26.94-51.89h-.33v68.18Z' transform='translate(-110.07 -37.17)' />
          <polygon fill={props.eventColor} points='1195.9 527.65 1195.9 420.35 1260.94 420.35 1260.13 436.1 1215.75 436.1 1215.75 463.78 1257.93 463.78 1257.93 479.53 1215.75 479.53 1215.75 511.83 1261.74 511.83 1261.09 527.65 1195.9 527.65' />
          <path fill={props.eventColor} d='M1496.12,564.82l-39-107.3h20.44l22.7,66.28q1.32,3.45,6.08,20.14.29-1.17,2.71-9.63t3.15-10.36q.88-2.49,11.13-33.07t11.21-33.36h20.21l-38.52,107.3Z' transform='translate(-110.07 -37.17)' />
          <polygon fill={props.eventColor} points='1533.72 527.65 1533.72 420.35 1598.76 420.35 1597.95 436.1 1553.57 436.1 1553.57 463.78 1595.76 463.78 1595.76 479.53 1553.57 479.53 1553.57 511.83 1599.57 511.83 1598.91 527.65 1533.72 527.65' />
          <path fill={props.eventColor} d='M1806.92,564.82V457.52H1828q5.71,8.93,23.22,36t21.31,33c.73,1.12,2,3,3.77,5.68s3,4.55,3.77,5.67q-.51-25.11-.51-26.07V457.52h18.09v107.3h-21.38l-48.42-75.44-3.51-5.79q.59,19.34.58,27.17v54.06Z' transform='translate(-110.07 -37.17)' />
          <polygon fill={props.eventColor} points='1910.14 527.65 1910.14 436.25 1878.87 436.25 1878.87 420.35 1961.19 420.35 1961.19 436.25 1930.06 436.25 1930.06 527.65 1910.14 527.65' />
        </g>
      </svg>
    )
})

EventLogo.defaultProps = {
    x: 0,
    y: 0,
    width: '215px',
    height: '122px'
}

export default withTheme(themes)(EventLogo)
