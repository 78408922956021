function getVehicleImageUrl (
    make: string,
    name: string,
    year: ?number,
    color: string = 'gray',
    angle: string = 'front_34',
    size: string = 'l',
    segment: string
) {
    return `${process.env.REACT_APP_VEHICLE_IMAGES_BASE_URL}${encodeURIComponent(make)}.${encodeURIComponent(name)}.${year || 'latest'}.${angle}.${color}.${size}.png?fallback=${getFallbackFromSegment(segment)}`
}

function getFallbackFromSegment(segment) {
    switch (true) {
        case /SUV/i.test(segment):
            return 'suv'
        case /truck/i.test(segment):
        case /pickup/i.test(segment):
            return 'truck'
        default:
            return 'sedan'
    }
}

export default getVehicleImageUrl
