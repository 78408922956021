// @flow
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import styles from './styles.module.scss'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'
import AbsoluteTime from 'components/AbsoluteTime'
import Timer from 'components/Timer'

import cn from 'classnames'

type Props = {
    date: Date
};

class TopBar extends Component<Props, { formattedDate: { [string]: string } }> {
    shouldComponentUpdate (nextProps, nextState) {
        return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)
    }

    // componentDidMount () {
        // this.refEvent = window.addEventListener('scroll', this.handleScroll)
    // }

    // componentWillUnmount () {
        // window.removeEventListener('scroll', this.refEvent)
    // }

    render () {
        const { t, theme } = this.props
        return (
            <>
                <div className={cn(styles.container)}>
                    <div className={cn(styles.innerContainer, {
                        [styles.centered]: !theme.showLogo
                    })}>
                        <div>
                            {theme.showLogo &&
                                <div className={styles.logo}>
                                    <img src={theme.logo} alt="" style={{ ...theme.logoImgStyles || {} }} />
                                </div>
                            }

                            <div className={styles.expiryText}>
                                <span>{t('event_expiry')}&nbsp;<strong><AbsoluteTime dateFormat='long' date={this.props.date} /></strong></span>
                            </div>
                        </div>

                        <div className={styles.timer}>
                            <Timer xsmall date={this.props.date} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(TopBar))
